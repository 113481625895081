import { CsvService } from './../services/csv.service';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { NgForm } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';

import { Packaging } from './../models/packaging';
import { Receiver } from './../models/receiver';
import { Item } from './../models/item';
import { Order } from '../models/order';
import { OrderService } from '../services/order.service';
import { Product } from '../models/product';
import { Shipping } from '../models/shipping';
import { Service } from '../models/service';

@Component({
  selector: 'order-batch',
  templateUrl: '../views/order.batch.html',
  providers: [
    OrderService,
    CsvService
  ]
})

export class OrderBatchComponent implements OnInit {

  csvHeader: string;
  csvFieldCount: number;
  loadingExampleCsv: boolean;
  loadingData: boolean;
  processing: boolean;
  errorMsg: string;
  orders: Order[];
  packageCount: number;
  savedOrders: Order[];
  file: File;

  constructor(
    private _orderService: OrderService,
    private _csvService: CsvService
  ) {
    this.processing = false;
    this.loadingData = false;
  }

  ngOnInit() {
    this.errorMsg = '';
    this.resetBatch();
    this.loadExampleCsv();
  }

  loadExampleCsv() {
    this.loadingExampleCsv = true;

    this._csvService.getOrdersCsv().subscribe(
      (lines: string[]) => {
        this.loadingExampleCsv = false;
        this.csvHeader = lines[0];
        this.csvFieldCount = this.csvHeader.split(';').length;
      }
    );
  }

  processFiles(fileList: FileList) {
    this.resetBatch();

    this.file = fileList[0];

    if (this.file.type != 'text/csv' && this.file.name.split('.').pop() != 'csv') {
      this.errorMsg = 'El archivo seleccionado no es un CSV';
      this.resetBatch();
      return;
    }

    this.readFileContent(this.file).then((content: string) => {
      this.processCSV(content);
    }).catch(error => console.log(error));
  }

  readFileContent(file: File) {
    const reader = new FileReader();

    return new Promise((resolve, reject) => {
      reader.onload = event => resolve((event.target as FileReader).result);
      reader.onerror = error => reject(error);
      reader.readAsText(file);
    });
  }

  processCSV(content: string) {
    this.errorMsg = '';
    const lines = content.split(/\r\n|\n/);
    const headerString = lines.shift();
    const headers = headerString.split(';');

    const lineCount = 27;

    if (headers.length !== lineCount) {
      this.errorMsg = `El encabezado no es válido (debe tener ${lineCount} campos)`;
      return;
    }

    if (lines.length > 1000) {
      this.errorMsg = 'El lote debe tener como máximo 1000 pedidos';
      return;
    }

    let parsedLines = lines.map(lineData => lineData.split(';')).filter(parsedLine => parsedLine.length > 1);

    const totalLines = parsedLines.length;

    parsedLines = parsedLines.filter(parsedLine => parsedLine.length === lineCount);

    if (totalLines !== parsedLines.length) {
      this.errorMsg = 'Líneas no válidas: ' + (totalLines - parsedLines.length);
      return;
    }

    // Ordenamos por número de paquete
    parsedLines = parsedLines.sort((line1, line2) => {
      if (line1[1] > line2[1]) {
        return 1;
      }
      if (line1[1] < line2[1]) {
        return -1;
      }
      return 0;
    });

    // Ordenamos por id de orden
    parsedLines = parsedLines.sort((line1, line2) => {
      if (line1[0] > line2[0]) {
        return 1;
      }
      if (line1[0] < line2[0]) {
        return -1;
      }
      return 0;
    });

    let workingOrderId;
    let workingPackage;
    let items: Item[];
    let shippings: Shipping[];
    const orders: Order[] = [];
    let packageCount = 0;
    let i = 0;
    let data: any;

    while (i < parsedLines.length) {
      workingOrderId = parsedLines[i][0];
      shippings = [];

      while (i < parsedLines.length && parsedLines[i][0] === workingOrderId) {
        workingPackage = parsedLines[i][1];
        items = [];

        while (i < parsedLines.length && parsedLines[i][0] === workingOrderId && parsedLines[i][1] === workingPackage) {
          const item = new Item();
          const product = new Product();

          data = parsedLines[i];

          product.sku = data[2];
          product.name = data[3];
          product.value = parseFloat(data[4]);
          product.width = parseInt(data[5]);
          product.height = parseInt(data[6]);
          product.length = parseInt(data[7]);
          product.weight = parseInt(data[8]);

          item.product = product;
          item.quantity = parseInt(data[9]);

          items.push(item);

          items.push();

          i++;
        }

        const shipping = new Shipping();
        const packaging = new Packaging();

        packaging.id = data[10];

        shipping.items = items;
        shipping.packaging = packaging;

        shippings.push(shipping);

        packageCount++;
      }

      const order = new Order();
      const receiver = new Receiver();
      const service = new Service();

      service.id = 1;

      receiver.name = data[11];
      receiver.surname = data[12];
      receiver.document = data[13];
      receiver.phone = data[14];
      receiver.email = data[15];
      receiver.company = data[16];
      receiver.streetName = data[17];
      receiver.streetNumber = data[18];
      receiver.floor = data[19];
      receiver.apartment = data[20];
      receiver.betweenStreet1 = data[21];
      receiver.betweenStreet2 = data[22];
      receiver.zipcode = data[23];
      receiver.province = data[24];
      receiver.city = data[25];
      receiver.neighborhood = data[26];

      order.receiver = receiver;
      order.service = service;

      order.clientOrderId = data[0];
      order.shippings = shippings;

      orders.push(order);
    }

    if (!orders.length) {
      this.errorMsg = 'La estructura del CSV es correcta pero no contiene ningún pedido.';
      return;
    }

    this.orders = orders;
    this.packageCount = packageCount;
  }

  resetBatch() {
    this.file = undefined;
    this.orders = [];
    this.savedOrders = [];
  }

  cancel() {
    this.errorMsg = '';
    this.resetBatch();
  }

  saveOrderBatch() {
    if (this.processing) {
      this.errorMsg = 'Procesando, por favor espere';
      return;
    }

    this.loadingData = true;
    this.processing = true;
    this.errorMsg = '';

    this._orderService.batchCreate(this.orders).subscribe(
      (response: any) => {
        this.loadingData = false;
        this.processing = false;
        this.errorMsg = '';

        this.resetBatch();

        this.savedOrders = response.data.map(order => new Order().hydrateWith(order));
      },
      (errorResponse: any) => {
        this.loadingData = false;
        this.processing = false;

        this.errorMsg = errorResponse instanceof HttpErrorResponse && typeof errorResponse.error.msg != 'undefined' ? errorResponse.error.msg : 'Ha ocurrido un error';
      }
    );
  }
}
